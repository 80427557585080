import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

export const constants = {
  CLASSROOM_LIST_SEARCH: 'CLASSROOM:LIST_SEARCH',
  CLASSROOM_CREATE: 'CLASSROOM:CREATE',
  CLASSROOM_UPDATE: 'CLASSROOM:UPDATE',
  CLASSROOM_DELETE: 'CLASSROOM:DELETE',
  CLASSROOM_GET_BY_ID: 'CLASSROOM:GET_BY_ID',
  CLASSROOM_GET_SELECT_OPTIONS: 'CLASSROOM:GET_SELECT_OPTIONS',
  CLASSROOM_GET_BUILDINGS_BY_CAMPUSES: 'CLASSROOM:GET_BUILDINGS_BY_CAMPUSES',
  CLASSROOM_CREATE_EXCEL_BULK: 'CLASSROOM:CREATE_EXCEL_BULK',
  CLASSROOM_IMPORT_LIST_SEARCH: 'CLASSROOM:IMPORT_LIST_SEARCH',
  CLASSROOM_IMPORT_GET_SELECT_OPTIONS: 'CLASSROOM:IMPORT_GET_SELECT_OPTIONS',
  CLASSROOM_IMPORT_GET_BUILDINGS_BY_CAMPUSES: 'CLASSROOM:IMPORT_GET_BUILDINGS_BY_CAMPUSES',
  CLASSROOM_IMPORT_CREATE: 'CLASSROOM:IMPORT_CREATE',
  CLASSROOM_CHANGE_ALL_DATA: 'CLASSROOM:CHANGE_ALL_DATA',
};

export const ClassroomSearchInitialValues: Types.IFilterClassroom = {
  classroom_code: '',
  name: '',
  status: [],
  buildings: [],
  classroom_features: [],
  classroom_types: [],
  classroom_ids: [],
  course_ids: [],
  term_id: undefined,
  select_all: false,
  add_list: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10,
  area_per_person_type: 'room',
  volume_per_person_type: 'room',
};

export const ClassroomHoursSearchInitialValues: Types.IFilterClassroom = {
  classroom_code: '',
  name: '',
  status: [1],
  buildings: [],
  classroom_types: [],
  classroom_ids: [],
  course_ids: [],
  term_id: undefined,
  add_list: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10,
  select_all: false
};

export const ClassroomFormInitialValues: Types.IClassroomItem = {
  classroom_id: undefined,
  classroom_code: '',
  status: 1,
  name: '',
  description: '',
  building_name: '',
  campus_name: '',
  door_order: undefined,
  exam_capacity: undefined,
  lecture_capacity: undefined,
  invigilator_count: undefined,
  classroom_type: '',
  features: []
};

export const ChangeAlldataModalInitialValues: Types.IFilterChangeAllDataModal = {
  status: 0
};

export const ClassroomFeatureOptions = (T: Translator, opts?: any) => [
  { label: T.t('gen_smartboard', opts), value: 'AT' },
  { label: T.t('gen_computer', opts), value: 'Bil' },
  { label: T.t('gen_dvd', opts), value: 'DVD' },
  { label: T.t('gen_internet_access', opts), value: 'Int' },
  { label: T.t('gen_air_conditioner', opts), value: 'Klima' },
  { label: T.t('gen_projector', opts), value: 'Proj' },
  { label: T.t('gen_fixed_curtain', opts), value: 'SbtPer' },
  { label: T.t('gen_sound_system', opts), value: 'SesSist' },
  { label: T.t('gen_video', opts), value: 'Vid' }
];

export const ClassroomCategoryOptions = (T: Translator, opts?: any) => [
  { label: T.t('gen_accommodation', opts), value: 1 },
  { label: T.t('gen_auxiliary', opts), value: 2 },
  { label: T.t('gen_circulation', opts), value: 3 },
  { label: T.t('gen_conference_hall', opts), value: 4 },
  { label: T.t('gen_gastronomy', opts), value: 5 },
  { label: T.t('gen_general_sanitary', opts), value: 6 },
  { label: T.t('gen_laboratory', opts), value: 7 },
  { label: T.t('gen_lecture_hall', opts), value: 8 },
  { label: T.t('gen_office_space', opts), value: 9 },
  { label: T.t('gen_other', opts), value: 10 },
  { label: T.t('gen_residential', opts), value: 11 },
  { label: T.t('gen_sanitary', opts), value: 12 },
  { label: T.t('gen_shared_facilities', opts), value: 13 },
  { label: T.t('gen_social_infrastructure', opts), value: 14 },
  { label: T.t('gen_special_infrastructure', opts), value: 15 },
  { label: T.t('gen_sports', opts), value: 16 },
  { label: T.t('gen_technical_infrastructure', opts), value: 17 }
];

export const ClassroomSeatingArrangementOptions = (T: Translator, opts?: any) => [
  { label: T.t('gen_fixed_seating', opts), value: 1 },
  { label: T.t('gen_armchair_seating', opts), value: 2 },
  { label: T.t('gen_sequential_seating', opts), value: 3 },
  { label: T.t('gen_round_type', opts), value: 4 },
  { label: T.t('gen_oval_type', opts), value: 5 },
  { label: T.t('gen_u_type', opts), value: 6 },
  { label: T.t('gen_l_type', opts), value: 7 },
  { label: T.t('gen_cluster_type', opts), value: 8 },
  { label: T.t('gen_amphitheater_type', opts), value: 9 }
];

export const ClassroomSeatingTypeOptions = (T: Translator, opts?: any) => [
  { label: T.t('gen_fixed_desk', opts), value: 1 },
  { label: T.t('gen_chair', opts), value: 2 },
  { label: T.t('gen_movable_desk', opts), value: 3 },
  { label: T.t('gen_table', opts), value: 4 }
];

export const ClassroomUserTypeOptions = (T: Translator, opts?: any) => [
  { label: T.t('gen_academic_staff', opts), value: 1 },
  { label: T.t('gen_admin_staff', opts), value: 2 },
  { label: T.t('gen_other', opts), value: 3 },
  { label: T.t('gen_public', opts), value: 4 },
  { label: T.t('gen_staff', opts), value: 5 },
  { label: T.t('gen_students', opts), value: 6 },
  { label: T.t('gen_teaching_activitity', opts), value: 7 },
  { label: T.t('gen_technician', opts), value: 8 },
  { label: T.t('gen_third_party', opts), value: 9 }
];

export const ClassroomSpecialUsingStateOptions = (T: Translator, opts?: any) => [
  { label: T.t('gen_exhibition_area', opts), value: 1 },
  { label: T.t("gen_female", opts), value: 2 },
  { label: T.t("gen_male", opts), value: 3 },
  { label: T.t("gen_unisex", opts), value: 4 },
  { label: T.t("gen_cleaning_staff", opts), value: 5 },
  { label: T.t("gen_mailboxes", opts), value: 6 },
  { label: T.t("gen_outdoor_terrace", opts), value: 7 },
  { label: T.t("gen_recycling", opts), value: 8 },
  { label: T.t("gen_waste_disposal", opts), value: 9 },
  { label: T.t("gen_server_room", opts), value: 10 },
  { label: T.t("gen_rehearsal_room", opts), value: 11 }
]


export const ClassroomStatusOptions = (T: Translator, opts?: any) => [
  { label: T.t('gen_in_use', opts), value: 1 },
  { label: T.t('gen_potentially_unused', opts), value: 2 },
  { label: T.t('gen_incomplete', opts), value: 3 },
  { label: T.t('gen_not_specified', opts), value: 4 },
  { label: T.t('gen_unused', opts), value: 5 }
]

export const ClassroomAttributeOptions = (T: Translator, opts?: any) => [
  { label: T.t('gen_large', opts), value: 1 },
  { label: T.t('gen_public', opts), value: 2 },
  { label: T.t('gen_small', opts), value: 3 },
  { label: T.t('gen_medium', opts), value: 4 },
  { label: T.t('gen_private', opts), value: 5 }
];

export const ClassroomUsageStateOptions = (T: Translator, opts?: any) => [
  { label: T.t('gen_in_use', opts), value: 1 },
  { label: T.t('gen_potentially_unused', opts), value: 2 },
  { label: T.t('gen_incomplete', opts), value: 3 },
  { label: T.t('gen_not_specified', opts), value: 4 },
  { label: T.t('gen_unused', opts), value: 5 }
];

export const ClassroomInventoryOptions = (T: Translator, opts?: any) => [
  { label: T.t('gen_table', opts), value: 1 },
  { label: T.t('gen_lectern', opts), value: 2 },
  { label: T.t('gen_blackboard', opts), value: 3 },
  { label: T.t('gen_projection', opts), value: 4 },
  { label: T.t('gen_air_conditioner', opts), value: 5 },
  { label: T.t('gen_door', opts), value: 6 },
  { label: T.t('gen_window', opts), value: 7 },
  { label: T.t('gen_camera', opts), value: 8 },
];

export const searchLocalizedLabel = (
  value: number,
  from: (T: Translator, opts?: any) => {
    label: any;
    value: number | string;
  }[],
  T: Translator): string => {
  const obj = from(T).find(_ => _.value === value);

  return obj ? obj.label : '-';
}

export const ImportingKeySelectOptions = (T: Translator, opts?: any) => [
  { label: T.t('gen_building_code_and_classroom_code', opts), value: 'BUILDINGCODE_CLASSROOMCODE' },
  { label: T.t('gen_do_not_check', opts), value: 'NONE' }
];